@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f8f9;
  overflow-y: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none !important;
}

a u {
  text-decoration: none !important;
}

.ql-tooltip {
  display: none;
}

textarea {
  outline: none !important;
  padding: 3px 4px;
}

input[type='number'] {
  -webkit-appearance: none !important;
  -moz-appearance: textfield !important; /* Firefox specific: remove increment arrows */
  appearance: none !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.ql-container {
  margin-top: 10px !important;
}

.swal2-html-container a {
  color: rgb(37 99 235);
  text-decoration: underline !important;
}

.ql-toolbar {
  margin-bottom: 30px !important;
}

.quill h2 {
  font-size: 18px !important;
  font-weight: bold;
}

.react-datepicker-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 6px !important;
}

.react-datepicker__input-container {
  width: 100%;
  height: 100%;
  border-radius: 6px !important;
}

.react-datepicker__input-container input {
  width: 100%;
  height: 100%;
  color: black !important;
  padding-left: 20px;
  border-radius: 6px !important;
}

canvas {
  width: 100% !important;
  height: 100% !important;
}

.pg-photo-container {
  width: 100% !important;
  height: 100% !important;
}

.shimmer-border {
  position: relative;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
  gap: 8px;
}

.shimmer-border::before {
  content: '';
  width: 101%; /* Adjusted to match the parent width */
  height: 101%; /* Adjusted to match the parent height */
  border-radius: 999px; /* Adjust to match the inner div's radius if needed */
  position: absolute;
  z-index: -1;
  top: 0; /* Centered to parent */
  left: 1; /* Centered to parent */
  background-image: linear-gradient(90deg, #5ddcff, #3c67e3 50%, #0811bb);
  opacity: 0.4;
  transition: opacity 0.4s;
}

/* Add hover effect */
.shimmer-border:hover::before {
  opacity: 1;
}

.shimmer-border.border-active::before {
  opacity: 0 !important;
}

.popup-enter {
  transform: scale(0);
  opacity: 0;
}
.popup-enter-active {
  transform: scale(1);
  opacity: 1;
  transition:
    transform 300ms,
    opacity 300ms;
}
.popup-exit {
  transform: scale(1);
  opacity: 1;
}
.popup-exit-active {
  transform: scale(0);
  opacity: 0;
  transition:
    transform 300ms,
    opacity 300ms;
}

.gold-glow-card {
  box-shadow: 0 0 3px rgba(227, 197, 76, 0.874); /* Glow effect */
  transition: box-shadow 0.3s ease;
}

.green-glow-card {
  box-shadow: 0 0 3px rgba(49, 196, 172); /* Glow effect */
  transition: box-shadow 0.3s ease;
}

/* Default table formatting */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

th,
td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid #e2e8f0;
}

th {
  background-color: #f8fafc;
  font-weight: bold;
  color: #1a202c;
}

tr:nth-child(even) {
  background-color: #f9fafb;
}

tr:hover {
  background-color: #edf2f7;
}

.markdown-content h3 {
  @apply my-3 text-xl font-semibold;
}

.markdown-content h4 {
  @apply my-2 text-lg font-semibold;
}

.markdown-content strong,
.markdown-content b {
  @apply font-bold;
}

.markdown-content em,
.markdown-content i {
  @apply italic;
}

.markdown-content p {
  @apply mb-4;
}

.markdown-content ul {
  @apply ml-6 list-disc;
}

.markdown-content ol {
  @apply ml-6 list-decimal;
}

.markdown-content a {
  @apply text-blue-500 hover:underline;
}
